import React from "react";
import { Button, Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext"; // Ensure the path matches where you've defined useAuth
import { FaDiscord } from "react-icons/fa";

export function Navigation() {
  const { isAuth, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    logout(); // This will remove the token and update isAuth state
    navigate("/login"); // Navigate to login after logout
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          🖥️ SessionScreen
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            {isAuth ? (
              <NavDropdown title="Account" id="basic-nav-dropdown" align="end">
                <NavDropdown.Item as={Link} to="/account/organization">
                  Organization
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/account/purchase">
                  Purchase
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://support.sessionscreen.com"
                  target="_blank"
                >
                  Support
                  <FaDiscord style={{ marginLeft: "4px" }} />
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogout}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <Nav.Link as={Link} to="/pricing">
                  Pricing
                </Nav.Link>
                <Nav.Link as={Link} to="/demo">
                  Demo
                </Nav.Link>
                <Nav.Link as={Link} to="/contact-us">
                  Contact Us
                </Nav.Link>
                <Nav.Link as={Link} to="/login">
                  Login
                </Nav.Link>
                <Button
                  as={Link}
                  to="/signup"
                  variant="primary"
                  className="ms-3 mt-3 mt-md-0 ms-md-3"
                >
                  Get Started For Free
                </Button>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
