import { Navigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

export const AuthenticatedRedirect = ({ children }) => {
  const { isAuth } = useAuth();

  if (isAuth) {
    return <Navigate to="/dashboard/sessions" replace />;
  }

  return children;
};
