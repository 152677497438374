import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { LoginView } from "../views/LoginView";

export const LoginContainer = () => {
  const { login } = useAuth();
  const [credentials, setCredentials] = useState({
    emailsfasdfasd: "",
    passwordsdfsdfadfa: "",
    email: "", // honeypot field
    password: "", // honeypot field
  });
  const [turnstileToken, setTurnstileToken] = useState("");
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [error, setError] = useState("");

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const validationParam = params.get("validation");
  let validationStatus = "";
  if (
    validationParam !== null &&
    validationParam !== undefined &&
    validationParam !== ""
  ) {
    validationStatus = validationParam;
  }

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (credentials.email || credentials.password) {
      // Honeypot fields should be empty; if not, it's likely a bot submission
      setError("Bot detected. Please try again.");
      return;
    }
    try {
      const loginUrl = `${apiBaseUrl}/accounts/api/v1/login/`;
      const { data } = await axios.post(
        loginUrl,
        {
          email: credentials.emailsfasdfasd,
          password: credentials.passwordsdfsdfadfa,
          turnstile_token: turnstileToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      localStorage.setItem("access_token", data.access);
      localStorage.setItem("refresh_token", data.refresh);
      login(data.access, data.refresh);
      navigate("/dashboard/sessions");
    } catch (error) {
      let errorMessage = "Login error: Please try again later.";
      if (error.response && error.response.data) {
        errorMessage =
          "Login error: " + Object.values(error.response.data).join(" ");
      }
      setError(errorMessage);
    }
  };

  return (
    <LoginView
      validationStatus={validationStatus}
      credentials={credentials}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      error={error}
      setTurnstileToken={setTurnstileToken}
    />
  );
};
