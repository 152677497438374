import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import { AuthProvider } from "./AuthContext";
import { AuthenticatedRedirect } from "./component/AuthenticatedRedirect"; // Import the component you just created
import { ContactUsContainer } from "./containers/ContactUsContainer";
import { LoginContainer } from "./containers/LoginContainer";
import { LogoutContainer } from "./containers/LogoutContainer";
import { SignupContainer } from "./containers/SignupContainer";
import { Navigation } from "./component/Navigation";
import { Footer } from "./component/Footer";
import { LandingPageView } from "./views/LandingPageView";
import { DemoView } from "./views/DemoView";
import { PricingOverviewContainer } from "./containers/PricingOverviewContainer";
import { HomeContainer } from "./containers/HomeContainer";
import { SessionDetailContainer } from "./containers/SessionDetailContainer";
import { RdpSessionDetailContainer } from "./containers/RdpSessionDetailContainer";
import { QuestionContainer } from "./containers/QuestionContainer";
import { StartCreateQuestionContainer } from "./containers/StartCreateQuestionContainer";
import { SubmitCreateQuestionContainer } from "./containers/SubmitCreateQuestionContainer";
import { SubmitRdpQuestionContainer } from "./containers/SubmitRdpQuestionContainer";
import { CreateSessionContainer } from "./containers/CreateSessionContainer";
import { GradeSessionContainer } from "./containers/GradeSessionContainer";
import { OrganizationContainer } from "./containers/OrganizationContainer";
import { PurchaseContainer } from "./containers/PurchaseContainer";
import { QuestionUpdateContainer } from "./containers/QuestionUpdateContainer";
import { BannerProvider, useBanner } from "./BannerContext";
import { PasswordResetRequestContainer } from "./containers/PasswordResetRequestContainer";
import { PasswordResetConfirmContainer } from "./containers/PasswordResetConfirmContainer";
import { SessionDashboardContainer } from "./containers/SessionDashboardContainer";
import { Alert } from "react-bootstrap";
import { PrivacyPolicyView } from "./views/PrivacyPolicyPage";
import { TermsOfServiceView } from "./views/TermsOfServicePage";

const Banner = () => {
  const { banner, hideBanner } = useBanner();

  if (!banner) return null;

  return (
    <Alert
      variant={banner.variant}
      onClose={hideBanner}
      className="text-center px-2 py-4"
      dismissible
    >
      {banner.message}
    </Alert>
  );
};

function App() {
  return (
    <AuthProvider>
      <BannerProvider>
        <BrowserRouter>
          <div className="d-flex flex-column min-vh-100">
            <Navigation />
            <Banner />
            <Container className="flex-grow-1">
              <Routes>
                <Route
                  path="/"
                  element={
                    <AuthenticatedRedirect>
                      <LandingPageView />
                    </AuthenticatedRedirect>
                  }
                />
                <Route path="/dashboard/sessions" element={<HomeContainer />} />
                <Route
                  path="/sessions/:sessionId"
                  element={<SessionDashboardContainer />}
                />
                <Route
                  path="/sessions/ssh/:sessionId"
                  element={<SessionDetailContainer />}
                />
                <Route
                  path="/sessions/rdp/:sessionId"
                  element={<RdpSessionDetailContainer />}
                />
                <Route
                  path="/sessions/grade/:sessionId"
                  element={<GradeSessionContainer />}
                />
                <Route
                  path="/sessions/create"
                  element={<CreateSessionContainer />}
                />
                <Route
                  path="/dashboard/questions"
                  element={<QuestionContainer />}
                />
                <Route
                  path="/account/organization"
                  element={<OrganizationContainer />}
                />
                <Route
                  path="/account/purchase"
                  element={<PurchaseContainer />}
                />
                <Route
                  path="/account/change-subscription"
                  element={<PurchaseContainer />}
                />
                <Route path="/contact-us" element={<ContactUsContainer />} />
                <Route path="/login" element={<LoginContainer />} />
                <Route path="/demo" element={<DemoView />} />
                <Route path="/logout" element={<LogoutContainer />} />
                <Route path="/signup" element={<SignupContainer />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyView />} />
                <Route
                  path="/terms-of-service"
                  element={<TermsOfServiceView />}
                />
                <Route
                  path="/password-reset"
                  element={<PasswordResetRequestContainer />}
                />
                <Route
                  path="/password-reset-confirm/:uidb64/:token"
                  element={<PasswordResetConfirmContainer />}
                />
                <Route path="/pricing" element={<PricingOverviewContainer />} />
                <Route
                  path="/questions/create"
                  element={<StartCreateQuestionContainer />}
                />
                <Route
                  path="/questions/update/:questionId"
                  element={<QuestionUpdateContainer />}
                />
                <Route
                  path="/questions/create/next/ssh/:questionId"
                  element={<SubmitCreateQuestionContainer />}
                />
                <Route
                  path="/questions/create/next/rdp/:questionId"
                  element={<SubmitRdpQuestionContainer />}
                />
              </Routes>
            </Container>
            <Footer />
          </div>
        </BrowserRouter>
      </BannerProvider>
    </AuthProvider>
  );
}

export default App;
