import { useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import { useAuth } from "../AuthContext";

export const useCommonContainerLogic = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [orgName, setOrgName] = useState("");
  const [planName, setPlanName] = useState("");
  const [questionsAllowed, setQuestionsAllowed] = useState(0);
  const [interviewsAllowed, setInterviewsAllowed] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { isAuth, isLoading: authLoading } = useAuth();
  const [emailVerified, setEmailVerified] = useState(false);

  useEffect(() => {
    if (authLoading) return;
    if (!isAuth) {
      window.location.href = "/login";
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const orgResponse = await axiosInstance.get(
          `${apiBaseUrl}/accounts/api/v1/organization`,
        );
        setOrgName(orgResponse.data.name);
        setPlanName(orgResponse.data.plan_name);
        setQuestionsAllowed(orgResponse.data.questions_allowed);
        setInterviewsAllowed(orgResponse.data.interviews_allowed);

        const emailResponse = await axiosInstance.get(
          `${apiBaseUrl}/accounts/api/v1/email-verified`,
        );
        setEmailVerified(emailResponse.data.email_verified);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [apiBaseUrl, authLoading, isAuth]);

  return {
    searchTerm,
    setSearchTerm,
    orgName,
    planName,
    isLoading,
    authLoading,
    emailVerified,
    questionsAllowed,
    interviewsAllowed,
  };
};
