import React, { useState, useEffect } from "react";
import { DataView } from "../views/DataView";
import { useCommonContainerLogic } from "./useCommonContainerLogic";
import { Button } from "react-bootstrap";
import { useContextMenu } from "../hooks/useContextMenu";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosConfig";
import { useNavigate } from "react-router-dom";

export const HomeContainer = () => {
  const {
    searchTerm,
    setSearchTerm,
    orgName,
    isLoading,
    authLoading,
    emailVerified,
    questionsAllowed,
    interviewsAllowed,
  } = useCommonContainerLogic();

  const [sessions, setSessions] = useState([]);
  const [initialSessions, setInitialSessions] = useState([]);
  const [lengthOfInitialData, setLengthOfInitialData] = useState(0);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [inactiveQuestions, setInactiveQuestions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiBaseUrl}/interviewers/api/v1/question-attempts`,
        );
        const transformedData = transformData(response.data);
        setSessions(transformedData);
        setInitialSessions(transformedData);
        setLengthOfInitialData(transformedData.length);
      } catch (error) {
        console.error("Error fetching question types:", error);
      }
    };

    const fetchInactiveQuestions = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiBaseUrl}/interviewers/api/v1/inactive-questions`,
        );
        setInactiveQuestions(response.data);
      } catch (error) {
        console.error("Error fetching inactive questions:", error);
      }
    };

    const transformData = (data) => {
      const transformedData = data.map((item) => ({
        session: item.title,
        id: item.id,
        questionTitle: item.question.title,
        questionType: item.question.type,
        closed: item.closed,
        candidate:
          item.candidate.first_name + " " + item.candidate.last_name[0] + ".",
        complete: item.completed,
        date: item.date ? new Date(item.date).toLocaleString() : "",
        questionConnection: item.connection,
        active: item.active,
      }));
      return transformedData;
    };

    fetchSessions();
    fetchInactiveQuestions();
  }, [apiBaseUrl]);

  const { contextMenu, contextMenuRef, onContextMenu, handleCloseContextMenu } =
    useContextMenu();

  const sessionColumns = [
    { header: "Title", field: "session" },
    { header: "Question", field: "questionTitle" },
    { header: "Candidate", field: "candidate" },
    { header: "Date", field: "date" },
    { header: "Is Completed?", field: "closed" },
    { header: "Is Graded?", field: "complete" },
  ];

  const getContextMenuItems = (rowData) => {
    const items = [];

    items.push({
      label: "Session Detail",
      onClick: () => {
        navigate(`/sessions/${rowData.id}`);
      },
    });

    if (!rowData.closed && !rowData.complete) {
      let label = "Start Session";
      if (rowData.active) {
        label = "Join Session";
      }
      items.push({
        label: label,
        onClick: () => {
          switch (rowData.questionConnection) {
            case "ssh":
              navigate(`/sessions/ssh/${rowData.id}`);
              break;
            case "rdp":
              navigate(`/sessions/rdp/${rowData.id}`);
              break;
            default:
              console.error("Unknown session_type: ", rowData.questionType);
          }
        },
      });
    }

    if (rowData.closed && !rowData.complete && !rowData.creating) {
      items.push({
        label: "Grade Session",
        onClick: () => {
          navigate(`/sessions/grade/${rowData.id}`);
        },
      });
    }

    if (!rowData.closed && !rowData.complete && !rowData.creating) {
      items.push({
        label: "Cancel Session",
        onClick: async () => {
          try {
            const response = await axiosInstance.delete(
              `${apiBaseUrl}/interviewers/api/v1/question-attempts/delete/${rowData.id}`,
            );
            if (response.status === 204) {
              const updatedSessions = sessions.filter(
                (s) => s.id !== rowData.id,
              );
              setSessions(updatedSessions);
              handleCloseContextMenu();
              window.location.reload();
            }
          } catch (error) {
            console.error("Error deleting session:", error);
          }
        },
      });
    }

    return items;
  };

  const CreateSessionButton = () => {
    const isDisabled = interviewsAllowed === 0;

    return isDisabled ? (
      <Button
        as={Link}
        to="/account/purchase"
        variant="primary"
        size="md"
        className="shadow-sm"
      >
        Purchase More Sessions
      </Button>
    ) : (
      <Button
        as={Link}
        to="/sessions/create"
        variant="primary"
        size="md"
        className="shadow-sm"
      >
        Create New Session
      </Button>
    );
  };

  const searchAction = () => {
    if (searchTerm === "") {
      setSessions(initialSessions);
    } else {
      const updatedSessions = initialSessions.filter((s) =>
        s.session.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setSessions(updatedSessions);
    }
  };

  return (
    <DataView
      orgName={orgName}
      data={sessions}
      columns={sessionColumns}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      dataType="Sessions"
      CreateButtonComponent={CreateSessionButton}
      isLoading={isLoading}
      authLoading={authLoading}
      emailVerified={emailVerified}
      onContextMenu={onContextMenu}
      contextMenu={contextMenu}
      contextMenuRef={contextMenuRef}
      contextMenuItems={
        contextMenu.rowData ? getContextMenuItems(contextMenu.rowData) : []
      }
      searchAction={searchAction}
      lengthOfInitialData={lengthOfInitialData}
      questionsAllowed={questionsAllowed}
      interviewsAllowed={interviewsAllowed}
      inactiveQuestions={inactiveQuestions}
    />
  );
};
