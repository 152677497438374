import React from "react";
import {
  Alert,
  Table,
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { LoadingNotice } from "../component/LoadingNotice";
import { EmailVerificationNotice } from "../component/EmailVerificationNotice";
import { FaClipboard, FaCheck } from "react-icons/fa";

const OrganizationView = ({
  organizationName,
  members,
  email,
  handleInvite,
  setEmail,
  isLoading,
  authLoading,
  emailVerified,
  inviteSent,
  dismiss,
  handleGenerateApiKey,
  apiKey,
  showApiKey,
  referralCode,
  copyToClipboard,
  isReferralCopied,
  isApiKeyCopied,
  transactions,
}) => {
  if (isLoading || authLoading) {
    return <LoadingNotice />;
  }

  if (!emailVerified) {
    return <EmailVerificationNotice />;
  }

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h2 className="text-center">Organization Dashboard</h2>
          {inviteSent && (
            <Alert variant="success" dismissible onClick={dismiss}>
              Invite Sent!
            </Alert>
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6} className="mb-3">
          <Card className="h-100">
            <Card.Header>
              <h4 className="mb-0">Organization Details</h4>
            </Card.Header>
            <Card.Body>
              <Table borderless>
                <tbody>
                  <tr>
                    <td>
                      <strong>Organization Name</strong>
                    </td>
                    <td>{organizationName}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Referral Code</strong>
                    </td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => copyToClipboard(referralCode, "referral")}
                      title="Click to copy"
                    >
                      {referralCode}{" "}
                      {isReferralCopied ? (
                        <FaCheck style={{ color: "green" }} />
                      ) : (
                        <FaClipboard />
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-3">
          <Card className="h-100">
            <Card.Header>
              <h4 className="mb-0">Invite A User To Organization</h4>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleInvite}>
                <Form.Group controlId="inviteEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3">
                  Invite
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Card>
            <Card.Header>
              <h4 className="mb-0">Members</h4>
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {members.map((member, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{member}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Header>
              <h4 className="mb-0">Transaction History</h4>
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount Spent</th>
                    <th>Interviews</th>
                    <th>Questions</th>
                    <th>Bonus Interviews</th>
                    <th>Bonus Questions</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction, index) => (
                    <tr key={index}>
                      <td>{new Date(transaction.date).toLocaleDateString()}</td>
                      <td>${transaction.amount_spent}</td>
                      <td>{transaction.question_attempts}</td>
                      <td>{transaction.questions}</td>
                      <td>{transaction.bonus_question_attempts}</td>
                      <td>{transaction.bonus_questions}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4 mb-4">
        <Col>
          <Card className="h-100">
            <Card.Header>
              <h4 className="mb-0">API Key Management</h4>
            </Card.Header>
            <Card.Body>
              <Alert variant="warning" dismissible>
                <strong>Warning:</strong> Generating a new API key will
                invalidate any previous keys again. Make sure to copy it
                immediately.
              </Alert>
              <Button
                variant="primary"
                onClick={handleGenerateApiKey}
                className="d-block mx-auto"
                style={{ width: "200px" }}
              >
                Get API Key
              </Button>
              {showApiKey && (
                <span>
                  <strong>New API Key:</strong>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => copyToClipboard(apiKey, "apiKey")}
                  >
                    {apiKey}
                    {isApiKeyCopied ? (
                      <FaCheck style={{ color: "green", cursor: "pointer" }} />
                    ) : (
                      <FaClipboard />
                    )}
                  </div>
                </span>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationView;
