import React, { useState } from "react";
import { Container, Row, Col, Card, Table, Button } from "react-bootstrap";
import { Sidebar } from "../component/Sidebar";
import { SearchBar } from "../component/SearchBar";
import { DataTable } from "../component/DataTable";
import { LoadingNotice } from "../component/LoadingNotice";
import { EmailVerificationNotice } from "../component/EmailVerificationNotice";
import { cancelQuestion } from "../hooks/cancelQuestion";

import "./DataView.css";

export const DataView = ({
  orgName,
  data,
  columns,
  searchTerm,
  setSearchTerm,
  dataType,
  CreateButtonComponent,
  isLoading,
  authLoading,
  emailVerified,
  onContextMenu,
  contextMenu,
  contextMenuRef,
  contextMenuItems,
  searchAction,
  lengthOfInitialData,
  questionsAllowed,
  interviewsAllowed,
  inactiveQuestions,
}) => {
  const [showInactiveQuestions, setShowInactiveQuestions] = useState(false);

  const toggleInactiveQuestions = () => {
    setShowInactiveQuestions(!showInactiveQuestions);
  };

  if (isLoading || authLoading) {
    return <LoadingNotice />;
  }

  if (!emailVerified) {
    return <EmailVerificationNotice />;
  }

  const isEmpty = data.length === 0;

  const InactiveQuestionsTable = () => (
    <Table bordered className="mt-4">
      <thead>
        <tr>
          <th>Title</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {inactiveQuestions.map((question) => (
          <tr key={question.id}>
            <td>{question.title}</td>
            <td>{question.saving ? "Saving" : "Creating"}</td>
            <td>
              {question.saving ? null : (
                <Button
                  variant="danger"
                  onClick={() => cancelQuestion(question.id)}
                >
                  Cancel
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <Container fluid className="mt-3">
      <Row>
        <Col
          xs={12}
          md={3}
          lg={2}
          id="sidebar-wrapper"
          className="border-right"
        >
          <Sidebar />
        </Col>
        <Col xs={12} md={9} lg={10} id="page-content-wrapper">
          <Container>
            <Row className="align-items-center mb-4">
              <Col>
                <h2>
                  {orgName} {dataType}
                </h2>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Remaining</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Interview Sessions</td>
                      <td>{interviewsAllowed}</td>
                      <td>
                        {interviewsAllowed === 0 ? (
                          <span className="text-danger">Limit Reached</span>
                        ) : (
                          <span className="text-success">Available</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Interview Questions</td>
                      <td>{questionsAllowed}</td>
                      <td>
                        {questionsAllowed === 0 ? (
                          <span className="text-danger">Limit Reached</span>
                        ) : (
                          <span className="text-success">Available</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col xs="auto">
                {CreateButtonComponent && <CreateButtonComponent />}
              </Col>
            </Row>

            <Row className="mb-4">
              <Col md={8}>
                <SearchBar
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  placeholder={`Search ${dataType.toLowerCase()} by title`}
                  searchAction={searchAction}
                />
              </Col>
              <Col md={4} className="text-md-right">
                <Button
                  onClick={toggleInactiveQuestions}
                  className="mb-3"
                  variant="light"
                >
                  {showInactiveQuestions
                    ? "Hide Pending Questions"
                    : "Show Pending Questions"}
                </Button>
              </Col>
            </Row>
            {showInactiveQuestions && (
              <Row className="mt-4">
                <Col>
                  <h3>Pending Questions</h3>
                  {inactiveQuestions.length > 0 ? (
                    <InactiveQuestionsTable />
                  ) : (
                    <p>No pending questions found.</p>
                  )}
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                {isEmpty ? (
                  <Card className="text-center my-5 py-5">
                    <Card.Body>
                      <Card.Title>No {dataType} Found</Card.Title>

                      {lengthOfInitialData === 0 ? (
                        <Card.Text>
                          It looks like you haven't created any interview{" "}
                          {dataType.toLowerCase()} yet.
                          {inactiveQuestions.length > 0 && (
                            <p>
                              You may have some{" "}
                              <strong>Pending Questions</strong>
                            </p>
                          )}
                        </Card.Text>
                      ) : (
                        <Card.Text>
                          Nothing found matching the search term.
                        </Card.Text>
                      )}
                      <Row className="justify-content-center">
                        <Col xs="auto">
                          <CreateButtonComponent />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ) : (
                  <div className="table-responsive">
                    <DataTable
                      data={data}
                      columns={columns}
                      onContextMenu={onContextMenu}
                      contextMenu={contextMenu}
                      contextMenuRef={contextMenuRef}
                      contextMenuItems={contextMenuItems}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
