import React from "react";
import { Button, Container, Row, Col, Card, Spinner } from "react-bootstrap";
import { HelpSidebar } from "../component/HelpSidebar";

export const SessionDetailView = ({
  isSessionReady,
  questionTitle,
  questionDescription,
  handleAlertClose,
  terminalRef,
  sessionId,
  handleSubmit,
  isClosingSession,
  isStarted,
  type,
  videoRef,
}) => {
  return (
    <>
      <Container fluid="md" className="my-4">
        {!isStarted ? (
          <center>
            <h3>This Interview Session Has Not Started!</h3>
            <p>Please come back at the scheduled time</p>
          </center>
        ) : isClosingSession ? (
          <center>
            <h3>This Interview Session Is Closed!</h3>
            <p>You will hear from us shortly</p>
          </center>
        ) : (
          <>
            <Row className="mb-3">
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>{questionTitle}</Card.Title>
                    <Card.Text>{questionDescription}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <HelpSidebar includePreassessment={false} isInterview={true} />

            <Row className="mb-3">
              <Col>
                {!isSessionReady ? (
                  <center>
                    <Spinner animation="border" role="status"></Spinner>
                  </center>
                ) : type === "ssh" ? (
                  <div
                    id="terminal"
                    data-testid="terminal"
                    key={sessionId}
                    ref={terminalRef}
                    style={{
                      width: "100%",
                      height: "768px",
                      overflowY: "auto",
                      border: "1px solid #ddd",
                      borderRadius: "4px",
                      padding: "10px",
                      backgroundColor: "#000",
                      color: "#fff",
                    }}
                  />
                ) : (
                  <center>
                    <canvas
                      ref={videoRef}
                      style={{
                        width: "100%",
                        height: "768px",
                        overflowY: "auto",
                      }}
                    />
                  </center>
                )}
              </Col>
            </Row>

            <Row
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100px" }}
            >
              <Col className="d-flex justify-content-center">
                <Button
                  variant="primary"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!isSessionReady || isClosingSession}
                >
                  {!isSessionReady ? (
                    <div>Starting Session</div>
                  ) : (
                    <div>Close Session</div>
                  )}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};
